import BusArrivalResultsContext, { IContext } from '../context/BusArrivalResults';
import React, { Fragment, useContext } from 'react';
import '../styles/components/BusArrivalResults.sass';

export default function BusArrivalResults (): JSX.Element {

  const { routeName, stopName, arrivals }: IContext = useContext(BusArrivalResultsContext);

  // If there are arrivals, display the results.
  if (arrivals?.length) {

    return (
      <dl className="bus-arrival-results">
        <dt>Bus</dt>
        <dd>{routeName}</dd>
        <dt>Stop</dt>
        <dd>{stopName}</dd>
        <dt>Estimated Time of Arrival</dt>
        <dd>
          <ol>
            {arrivals.map((time: number, index: number) => (
              <li key={index}>{time} mins</li>
            ))}
          </ol>
        </dd>
      </dl>
    );
  }

  // Otherwise if a route and stop name are provided, show no results for stop.
  else if (routeName && stopName) {
    return (
      <p className="bus-arrival-results no-results">The <strong>{routeName}</strong> route has no expected arrivals to <strong>{stopName}</strong>.</p>
    );
  }

  // Otherwise, display nothing.
  return <Fragment />;

}
