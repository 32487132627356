import React, { Context } from 'react';

const defaults: IContext = {
  arrivals: []
};

const BusArrivalResultsContext: Context<IContext> = React.createContext(defaults);

export { BusArrivalResultsContext as default, defaults };

export interface IContext {
  routeName?: string;
  stopName?: string;
  arrivals: number[];
}
