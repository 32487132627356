import { useEffect, useRef } from 'react';

/**
 * Accepts a function that contains imperative, possibly effectful code, which will execute after component has been mounted.
 *
 * @param effect - Imperative function that can return a cleanup function
 * @param deps - If present, effect will only activate if the values in the list change.
 */
export default function useEffectAfterMount (effect: React.EffectCallback, deps? : React.DependencyList): void {

    const didMountRef = useRef(false);

    useEffect(() => {
      if (didMountRef.current) {
        effect();
      }
      else {
        didMountRef.current = true;
      }

    }, deps);

}
