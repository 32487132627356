import React, { Fragment } from 'react';

export default function Footer (): JSX.Element {
  const today = new Date();

  const date = [
    `${today.getMonth() + 1}`.padStart(2, '0'),
    `${today.getDate()}`.padStart(2, '0'),
    today.getFullYear()
  ].join('/');

  return (
    <Fragment>
      Schedule on {date}
    </Fragment>
  );
};
