import Route from '../models/Route';
import { useContext } from 'react';
import AppContext, { IContext } from '../context/App';

export default function useTranslocFeed (): (route: Route, stopId: number) => Promise<number[]> {
  const { agency }: IContext = useContext(AppContext);


  /**
   * Requests a list of arrivals for the given route and stop.
   *
   * @param routeId - The id of the route for which arrivals should be determined.
   * @param stopId - The id of the stop for which arrivals should be determined.
   *
   * @returns A promise with a list of arrivals.
   */
  return async (route, stopId): Promise<number[]> => {
    if(route.ids.length === 0) {
      return  Promise.resolve([]);
    }

    return window.fetch(`https://feeds.transloc.com/3/vehicle_statuses?agencies=${agency}&routes=${route.ids.join(',')}&include_arrivals=true`)
    .then((response) => {
      return response.json();
    })

    .then((data) => {
      const secondsSinceEpoch = Math.floor(new Date().getTime() / 1000);

      // Filter the response for arrivals for the correct stop.
      return data.arrivals.filter((arrival: { stop_id: number }) => arrival.stop_id === Number(stopId))

        // Transform the arrivals to minutes.
        .map((arrival: { timestamp: number }) => Math.round((arrival.timestamp - secondsSinceEpoch) / 60))

        // Ensure that no times in the past will display.
        .filter((minutes: number) => minutes > 0);
    });
  };

}
