export default class Stop {

  name: string;
  id: number;

  constructor (name: string, id: number) {
    this.name = name;
    this.id = id;
  }

}
