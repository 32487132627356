import $ from 'jquery';
import BusArrivalResults from './BusArrivalResults';
import { Primitive } from '../system/Types';
import Route from '../models/Route';
import Stop from '../models/Stop';
import useEffectAfterMount from '../hooks/useEffectAfterMount';
import useTranslocFeed from '../hooks/useTranslocFeed';
import AppContext, { IContext as IAppContext } from '../context/App';
import BusArrivalResultsContext, { IContext as IResultsContext } from '../context/BusArrivalResults';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import '../styles/components/EstimateBusArrival.sass';

let pollingIntervalId: number | undefined = undefined;

/**
 * Updates the state of a value and stores it in local storage, either setting the value or removing the key when the value is null.
 *
 * @param key - The name of the key where the value is created/updated/removed from local storage.
 * @param value - The value which is to be created/updated/removed from local storage.
 * @param setStateAction - The state action which is used to record the state of the value.
 */
function updateAndStoreState<T extends Primitive> (key: string, value: T, setStateAction: React.Dispatch<React.SetStateAction<T>>): void {
  setStateAction(value);

  if (value) {
    window.localStorage.setItem(key, value.toString());
  }
  else {
    window.localStorage.removeItem(key);
  }
}

export default function EstimateBusArrival ({ id, fallbackStop, fallbackRoutes }: { id: string; fallbackStop: Stop; fallbackRoutes: Route[] }): JSX.Element {

  const translocFeed = useTranslocFeed();
  const { routes, stops, pollingInterval }: IAppContext = useContext(AppContext);
  const [ arrivals, setArrivals ] = React.useState<number[] | null>(null);
  const [ isSettingOpen, setIsSettingOpen ] = useState(false);

  // Set up states to load values from local storage.
  const [ routeName, setRouteName ] = useState(window.localStorage.getItem(`${id}_routeName`));
  const [ stopId, setStopId ] = useState(Number(window.localStorage.getItem(`${id}_stopId`)));

  // Update results context when arrivals is set.
  const resultsContext = useMemo(() => ({
    routeName: routeName,
    stopName: stops.find((stop) => stop.id === stopId)?.name,
    arrivals
  } as IResultsContext), [arrivals]);

  // Update results provider when context changes.
  const results = useMemo(() => (
    <BusArrivalResultsContext.Provider value={resultsContext}>
      <BusArrivalResults />
    </BusArrivalResultsContext.Provider>
  ), [resultsContext]);

  // Initialize component
  useEffect(() => {

    // Collapse settings.
    $(`#${id} > .settings > form`).hide();

    // Try to load arrivals from initial values in local storage
    pollTranslocFeed(routeName, stopId).then(async (arrivals) => {

      // Remove the fallback which is included in local storage.
      const fallbacks = fallbackRoutes.filter((route) => route.name !== routeName);

      // If there are no arrivals, step through the fallbacks
      if (!arrivals.length) {
        for (const route of fallbacks) {
          updateAndStoreState<string | null>(`${id}_routeName`, route.name, setRouteName);
          updateAndStoreState(`${id}_stopId`, fallbackStop.id, setStopId);

          const arrivals = await pollTranslocFeed(route.name, fallbackStop.id) as number[];

          // If arrivals where found, avoid fetching additional fallbacks.
          if (arrivals.length) {
            break;
          }
        }
      }

    });

  }, []);

  // Collapse and expand settings
  useEffectAfterMount(() => {
    $(`#${id} > .settings > form`).slideToggle(500);
  }, [isSettingOpen]);

  return (
    <div id={id} className="estimate-bus-arrival">

      <div className={`settings${isSettingOpen ? ' open' : ''}`}>
        <button onClick={(): void => setIsSettingOpen(!isSettingOpen)}>Settings</button>
        <form onSubmit={formSubmitHandler}>
          <p>1323 to College Avenue Student Center</p>

          <label>
            <select required name="route" defaultValue={routeName ?? undefined}>
              <option></option>
              {routes.map((route, index) => (
                <option key={index} value={route.name}>{route.name}</option>
              ))}
            </select>
            <span>Bus</span>
          </label>

          <label>
            <select required name="stop" defaultValue={stopId || ''}>
              <option></option>
              {stops.map((stop, index) => (
                <option key={index} value={stop.id}>{stop.name}</option>
              ))}
            </select>
            <span>Stop</span>
          </label>

          <button>Predict</button>
        </form>
      </div>

      {results}

    </div>
  );

  /**
   * Handles the form event.
   *
   * @param event - The event which triggered this call.
   */
  function formSubmitHandler (event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const routeName = form.route.selectedOptions[0].value;
    const stopId = Number(form.stop.selectedOptions[0].value);

    updateAndStoreState(`${id}_routeName`, routeName, setRouteName);
    updateAndStoreState(`${id}_stopId`, fallbackStop.id, setStopId);

    setStopId(stopId);

    // Try to load arrivals
    pollTranslocFeed(routeName, stopId);
  }

  /**
   * Polls Transloc for updated arrival time.
   *
   * @param routeName - The name of the route for which to receive arrival times.
   * @param stopId - The stop to receive arrivals.
   *
   * @returns A list of arrivals
   */
  function pollTranslocFeed (routeName: string | null, stopId: number): Promise<number[]> {

    // Clear active polling interval
    if (pollingIntervalId) {
      window.clearInterval(pollingIntervalId);
    }

    // Only fetch and poll if there is a route and stop id.
    if (routeName && stopId) {
      const route = routes.find((route) => route.name === routeName);

      if (route) {
        pollingIntervalId = window.setInterval(() => fetchFeed(route), pollingInterval * 1000);
        return fetchFeed(route);
      }
    }

    // Otherwise, clear arrivals
    setArrivals(null);

    // Return a resolved promise.
    return Promise.resolve([]);

    async function fetchFeed (route: Route): Promise<number[]> {
      return translocFeed(route, stopId).then((arrival) => {
        setArrivals(arrival);
        return arrival;
      });
    }

  }

}
