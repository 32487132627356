export default class Route {
  name: string;
  ids: number[];

  constructor (name: string, ids: number[]) {
    this.name = name;
    this.ids = ids;
  }

}
