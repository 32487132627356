//Keys for different Routes
export const RouteWeekend1 = 'Weekend1';
export const RouteWeekend2 = 'Weekend2';
export const RouteSummer1 = 'Summer1';
export const RouteSummer2 = 'Summer2';
export const RouteEE = 'EE';
export const RouteF = 'F';
export const RouteAllCampuses = 'All Campuses';

//Regex for different routes
export const routeRegexKeyMap = new Map<string, RegExp>([
  [RouteWeekend1, /^weekend1$/i],
  [RouteWeekend2, /^weekend2$/i],
  [RouteSummer1, /^summer1$/i],
  [RouteSummer2, /^summer2$/i],
  [RouteEE, /^EE$/i],
  [RouteF, /^F$/i],
  [RouteAllCampuses, /^allcampuses$/i]
]);

export const TranslocFeedUrl = 'https://feeds.transloc.com/3/routes?agencies=1323&include_arrivals=true';




//Keys for different Routes
export const StopHenderson = 'Henderson';
export const StopCollegeHall = 'College Hall';
export const StopGibbons = 'Gibbons';
export const StopKatzenbach = 'Katzenbach';

//Regex for different routes
export const stopRegexKeyMap = new Map<string, RegExp>([
  [StopHenderson, /^henderson$/i],
  [StopCollegeHall, /^collegehall$/i],
  [StopGibbons, /^gibbons$/i],
  [StopKatzenbach, /^katzenbach$/i]
]);
export const TranslocStopFeedUrl = 'https://feeds.transloc.com/3/stops?include_routes=true&agencies=1323';
