import App from './App';
import React from 'react';
import Route from '../models/Route';
import Stop from '../models/Stop';
import { defaults } from '../context/App';
import { routeRegexKeyMap, stopRegexKeyMap } from '../utils/constants';
import { useEffect, useState } from 'react';
import * as Constants from '../utils/constants';
import '../styles/components/App.sass';

export default function InitializeApp (): JSX.Element {
  const [routesMap, _setRoutesMap] = useState(new Map<string, number[]>([
    [Constants.RouteWeekend1, []],
    [Constants.RouteWeekend2, []],
    [Constants.RouteSummer1, []],
    [Constants.RouteSummer2, []],
    [Constants.RouteEE, []],
    [Constants.RouteF, []],
    [Constants.RouteAllCampuses, []]
  ]
  ));
  const setRoutesMap = (k: string, v: number[]): void => _setRoutesMap(new Map(routesMap.set(k, v)));
  const [stopsMap, _setStopsMap] = useState(new Map<string, number | undefined>([
    [Constants.StopHenderson, undefined],
    [Constants.StopCollegeHall, undefined],
    [Constants.StopGibbons, undefined],
    [Constants.StopKatzenbach, undefined]
  ]
  ));
  const setStopsMap = (k: string, v: number): void => _setStopsMap(new Map(stopsMap.set(k, v)));

  const [hasInitialized, setHasInitialized] = useState(false);

  function populateRoutesMap (routeName: string, routeId: number): void {
    routeName = routeName.replace(/\s/g, '');
    routeName = routeName.replace(/Route/g, '');

    routeRegexKeyMap.forEach((value: RegExp, key: string) => {
      if (value.test(routeName)) {
        setRoutesMap(key, routesMap.get(key) !== undefined ? [...routesMap.get(key) as number[], routeId] : [routeId]);
        return;
      }
    });
  }

  function populateStopsMap (stopName: string, stopId: number): void {
    stopName = stopName.replace(/\s/g, '');
    stopName = stopName.replace(/Route/g, '');

    stopRegexKeyMap.forEach((value: RegExp, key: string) => {
      if (value.test(stopName)) {
        setStopsMap(key, stopId);
        return;
      }
    });
  }

  useEffect(() => {
    async function parseRouteData (): Promise<void> {
      const routeData = await (await fetch(Constants.TranslocFeedUrl)).json();
      const stopData = await (await fetch(Constants.TranslocStopFeedUrl)).json();
      routeData.routes.forEach((route: { long_name: string; id: number }) => populateRoutesMap(route.long_name, route.id));

      routesMap.forEach(function (value, key) {
          console.log(key + ' = ' + value);
      });

      routeData.routes.forEach((route: { short_name: string; id: number }) => populateRoutesMap(route.short_name.toLowerCase(), route.id));

      routesMap.forEach(function (value, key) {
          console.log(key + ' = ' + value);
      });

      stopData.stops.forEach((stop: {name: string; id: number}) => populateStopsMap(stop.name, stop.id));
      setHasInitialized(true);
    }
    parseRouteData();
  }, [_setRoutesMap]);

  function getRouteIds (routeKey: string): number[] {
    return routesMap.get(routeKey) || [];
  }

  function getStopId (stopKey: string): number {
    return stopsMap.get(stopKey) || 0;
  }

  /*
   * Assemble React.Context
   *
   */
  //Estimate One configuration
  const fallbackStopOne = new Stop(Constants.StopCollegeHall, getStopId(Constants.StopCollegeHall));

  const fallbackRoutesOne = [
    new Route(Constants.RouteEE, getRouteIds(Constants.RouteEE)),
    new Route(Constants.RouteWeekend1, getRouteIds(Constants.RouteWeekend1)),
    new Route(Constants.RouteSummer1, getRouteIds(Constants.RouteSummer1)),
    new Route(Constants.RouteAllCampuses, getRouteIds(Constants.RouteAllCampuses))
  ];

  //Estimate Two configuration
  const fallbackStopTwo = fallbackStopOne;

  const fallbackRoutesTwo = [
    new Route(Constants.RouteF, getRouteIds(Constants.RouteF)),
    new Route(Constants.RouteWeekend2, getRouteIds(Constants.RouteWeekend2)),
    new Route(Constants.RouteSummer2, getRouteIds(Constants.RouteSummer2))
  ];

  //Aggregate the context for the application.
  const context = {
    ...defaults,

    routes: [
      ...fallbackRoutesOne,
      ...fallbackRoutesTwo
    ].sort((a, b) => a.name.localeCompare(b.name)),

    stops: [
      fallbackStopOne,
      new Stop(Constants.StopHenderson, getStopId(Constants.StopHenderson)),
      new Stop(Constants.StopKatzenbach, getStopId(Constants.StopKatzenbach)),
      new Stop(Constants.StopGibbons, getStopId(Constants.StopGibbons))
    ]
  };

  return (
    <>
      {
        hasInitialized && <div>
          <App context={context}
            fallbackStopOne={fallbackStopOne}
            fallbackRoutesOne={fallbackRoutesOne}
            fallbackStopTwo={fallbackStopTwo}
            fallbackRoutesTwo={fallbackRoutesTwo} />
        </div>
      }
    </>
  );
}
