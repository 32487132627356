import EstimateBusArrival from './EstimateBusArrival';
import React from 'react';
import Route from '../models/Route';
import Stop from '../models/Stop';
import AppContext, { IContext } from '../context/App';
import '../styles/components/App.sass';

export default function App ({ context, fallbackStopOne, fallbackRoutesOne, fallbackStopTwo, fallbackRoutesTwo }: {context: IContext; fallbackStopOne: Stop; fallbackRoutesOne: Route[]; fallbackStopTwo: Stop; fallbackRoutesTwo: Route[] }): JSX.Element {
  return (
    <AppContext.Provider value={context}>
      <div className="app">
        <EstimateBusArrival id="estimateOne" fallbackStop={fallbackStopOne} fallbackRoutes={fallbackRoutesOne} />
        <EstimateBusArrival id="estimateTwo" fallbackStop={fallbackStopTwo} fallbackRoutes={fallbackRoutesTwo} />
      </div>
    </AppContext.Provider>
  );
};
