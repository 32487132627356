import Route from '../models/Route';
import Stop from '../models/Stop';
import React, { Context } from 'react';

const defaults: IContext = {
  agency: 1323,
  routes: [],
  stops: [],
  pollingInterval: 40
};

const AppContext: Context<IContext> = React.createContext(defaults);

export { AppContext as default, defaults };

export interface IContext {
  agency: number;
  routes: Route[];
  stops: Stop[];
  pollingInterval: number;
}
